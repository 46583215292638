export const environment = {
	production: true,
	isLive: false,
	//API_ENDPOINT: 'https://formpay-remote.martechapps.com/',
	API_ENDPOINT: 'https://apps-remote.martechapps.com/',
	AppName: "API Connector",
	confirmation_link: 'http://192.168.4.93:3100/connector/app/auth/verify/',
	Bearer: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjBlNTlkOTA1NGNmYzk2ODFhNzc5MmY5Iiwicm9sZSI6ImFkbWluIiwiZXhwIjoxNjkwMDAxODYxLCJpc3MiOiJodHRwczpcL1wvYXBwcy5jZWRjb21tZXJjZS5jb20iLCJhdWQiOiIxMjcuMC4wLjEiLCJ0b2tlbl9pZCI6IjYyZGEyZTQ1NGRkYmIwNjlmYTdmMDJhMiJ9.Fj7GbkMGJN-QqDGeQ60BKFSNXGZISXFcJ5mnBjCdMhsKgiEM3MS9SMOlB-GsZwg_dagMvx1_Gb3dhUp6gWNszrBPIxSENrLU9zTHAAJnuHA_zBtyHr_7JN-V3Jr9emSY6rCYE49zDLB-xWMgE0qu4uDCeJeegs0fy8hmQN6JZz8KBuQXkltvEkpFOY5IZf_VKZX3IeVI3Ed8JNqqbj9AJiTDfgpI1NqKc7DZZfrvM4PqWgM1yG446ObQkz7PMIET2AMy0OjHiAXCa_zqyX45Ugy-9jkvDMdIkirg4jTmQhDqF5hN6U0uIOPMAlGXohtRQ9opSUGrr-5DHRLPwlMrTQ'
	//Bearer:
		// "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjBlNTlkOTA1NGNmYzk2ODFhNzc5MmY5Iiwicm9sZSI6ImFkbWluIiwiZXhwIjoxNjc4MzcxNTY1LCJpc3MiOiJodHRwczpcL1wvYXBwcy5jZWRjb21tZXJjZS5jb20iLCJhdWQiOiIxMjcuMC4wLjEiLCJ0b2tlbl9pZCI6IjYyMjhiNzZkYzdmNDA2NDM1YTYyMDU1MiJ9.TW-V24twU6HZWLcatzJWlT6wmFEOKLGo9Ndt97fOmYS-Cfa4fCAfDtgWTC3ZTOLVSQVLPN7-7UFjrfiBh8kjriou12f1GGOJDkGhkww3Jywx3q06ek2M_LUy2JGEu0CbL9V0pmJE0sGG0pYK2QQo6-PqX7K7Hbda-CZTEpaTkOZykK2L6Bs_BPKrNNeexZBeRG6mmav5Xk-oHRRsv0ncLbzh-oFJBqETfilbbUF5WgPaDA0u-yKcAfdUSu-i9ruWIuJAtzDh89KuOc3QJxh33P-AsCbGV7jYVqZ2POxCVsbv3WFh0w8M2E_GdcwSIia8x-W9KaRh-n0MxGReBs6YLg"
};
/* Bearer on the last line is App token for server */
